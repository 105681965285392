<template>
  <section id="k-d-a-hero">
    <base-img
      :gradient="gradient"
      :height="imgHeight"
      :src="imageSrc"
      max-width="100%"
      flat
      tile
    >
      <v-row
        align="end"
        class="ma-0 fill-height"
        justify="center"
      >
        <v-col cols="12">
          <component
            :is="m_objTitle.strComponent"
            :class="getTextClasses(m_objTitle.eType, ['text-left', 'white--text', 'font-weight-black'])"
            v-html="m_objTitle.htmlText"
          />
        </v-col>
      </v-row>
    </base-img>
  </section>
</template>

<script>
  import { HexToRGBA, RGBAtoCSS } from 'vuetify/lib/util/colorUtils'
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'SectionHero',

    mixins: [TextClass],

    computed: {
      gradient () {
        const color1 = `${this.$vuetify.theme.themes.light.accent1}CC`
        const color2 = `${this.$vuetify.theme.themes.light.accent2}CC`
        const overlay1 = RGBAtoCSS(HexToRGBA(color1))
        const overlay2 = RGBAtoCSS(HexToRGBA(color2))

        return `to right, ${overlay1}, ${overlay2}`
      },
      imgHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? 550 : 350
      },
      imageSrc () {
        return require('@/assets/bg/bg-hero.jpg')
      },
      m_objTitle () {
        return {
          htmlText: 'PROFESSIONAL<br>SOFTWARE HOUSE',
          strComponent: 'h1',
          eType: this.eContentType.DISPLAY,
        }
      },
    },
  }
</script>
